import React ,{ Component }from "react";
import { FiClock } from "react-icons/fi";
import { GrConfigure } from "react-icons/gr";
import { SlSupport } from "react-icons/sl";
import { LuPackageOpen } from "react-icons/lu";
import { FaMoneyBillAlt } from "react-icons/fa";
import { FaPeopleLine } from "react-icons/fa6";

const ServiceList = [
    {
        icon: <FiClock />,
        title: 'Control de Asistencia',
        description: 'Registra la entrada y salida de tus empleados de manera eficiente.',
        soon: false
    },
    {
        icon: <GrConfigure />,
        title: 'Personalización Avanzada',
        description: 'Adapta la plataforma a las necesidades específicas de tu empresa.',
        soon: false
    },
    {
        icon: <SlSupport />,
        title: 'Soporte Dedicado',
        description: 'Ofrecemos asistencia técnica y atención personalizada para resolver tus dudas y problemas.',
        soon: false
    },
    { 
        icon: <LuPackageOpen />,
        title: 'Logística Optimizada',
        description: 'Mejora la eficiencia en la gestión de inventario, envíos y seguimiento de pedidos.',
        soon: true
    },
    {
        icon: <FaMoneyBillAlt />,
        title: 'Contabilidad Integrada',
        description: 'Organiza y supervisa tus finanzas con herramientas de contabilidad avanzadas.',
        soon: true
    },
    { 
        icon: <FaPeopleLine />,
        title: 'Gestión de RRHH',
        description: 'Simplifica la administración del personal y procesos laborales de la empresa.',
        soon: true
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            
                            <a href="/service-details">
                            
                            
                                <div className="service service__style--2">
                                {val.soon ? <div class="soon_tag">PRONTO</div> : <></>}
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
