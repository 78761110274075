// React Required
import React, { Component } from "react";
// import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client";
import CookieConsent from "react-cookie-consent";

// Create Import File
import "./index.scss";

import PageScrollTop from "./component/PageScrollTop";

// Home layout
import Paralax from "./home/Paralax";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import BlogDetails from "./elements/BlogDetails";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <CookieConsent>This website uses <a href="/policy" className="a-cookies">cookies</a> to enhance the user experience.</CookieConsent>
        <PageScrollTop>
          <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Paralax} />
            <Route exact path={`${process.env.PUBLIC_URL}/policy`} component={BlogDetails} />
          </Switch>
        </PageScrollTop>
      </BrowserRouter>
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
serviceWorker.register();
